class MrSetAttributeWhenIntersecting extends HTMLElement {
	#observer: IntersectionObserver;

	constructor() {
		super();

		this.#observer = new IntersectionObserver( ( entries ) => {
			let delay: number = parseInt( this.dataset.delay ?? '0', 10 );
			if ( Number.isNaN( delay ) ) {
				delay = 0;
			}

			if ( !window.matchMedia( '(min-width: 23.375rem)' ).matches ) {
				delay = 0;
			}

			entries.forEach( ( entry: IntersectionObserverEntry ) => {
				if ( entry.isIntersecting ) {
					setTimeout( () => {
						entry.target.setAttribute(
							this.dataset.attributeToAdd ?? 'data-intersected-with-viewport',
							''
						);
					}, delay );

					this.#observer.unobserve( entry.target );
				}
			} );
		}, {
			threshold: parseFloat( this.dataset.threshold ?? '0' ),
		} );
	}

	connectedCallback(): void {
		this.#observer.observe( this );
	}

	disconnectedCallback(): void {
		this.#observer.disconnect();
	}
}

customElements.define( 'mr-set-attribute-when-intersecting', MrSetAttributeWhenIntersecting );
