import '@mrhenry/wp--bugsnag-config';
import '@mrhenry/wp--autoplay-in-view';

import './modules/mr-hover-animation';
import './modules/mr-navigation-overlay';
import './modules/mr-set-attribute-when-intersecting';
import './modules/mr-video';
import './modules/mr-video-overlay';

import { initMenuButtons, MrCarousel, MrInputSink, MrPlayPauseToggle, MrAccordion } from '@mrhenry/wp--mr-interactive';

initMenuButtons();
customElements.define( 'mr-carousel', MrCarousel );
customElements.define( 'mr-input-sink', MrInputSink );
customElements.define( 'mr-play-pause-toggle', MrPlayPauseToggle );
customElements.define( 'mr-accordion', MrAccordion );
