class MrHoverAnimation extends HTMLElement {
	static observer?: IntersectionObserver = ( () => {
		if ( !( 'IntersectionObserver' in window ) ) {
			return;
		}

		return new IntersectionObserver( ( entries ) => {
			if ( window.matchMedia( '(min-width: 1024px), (hover)' ).matches ) {
				return;
			}

			for ( const entry of entries ) {
				entry.target.setAttribute( 'data-hover', '' );
			}
		}, {
			threshold: 1,
		} );
	} )();

	private mouseEnterHandler = () => {
		this.setAttribute( 'data-hover', '' );
	};

	private animationEndHandler = () => {
		requestAnimationFrame( () => {
			this.removeAttribute( 'data-hover' );
		} );
	};

	connectedCallback() {
		MrHoverAnimation.observer?.observe( this );

		this.addEventListener( 'mouseenter', this.mouseEnterHandler );
		this.addEventListener( 'animationend', this.animationEndHandler );
	}

	disconnectedCallback() {
		MrHoverAnimation.observer?.observe( this );

		this.removeEventListener( 'mouseenter', this.mouseEnterHandler );
		this.removeEventListener( 'animationend', this.animationEndHandler );
	}
}

window.customElements.define( 'mr-hover-animation', MrHoverAnimation );
